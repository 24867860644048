<template>
    <div>
        <b-modal id="modal-src-password" centered :title="$t('enter_sec_password')" hide-footer>

            <div class="text-left">
                <b-form @submit.prevent="confirmPassword">

                    <b-form-group class="mt-4">
                        <h4 class="font-weight-semibold text-14">{{ $t('sec_password') }}</h4>
                        <b-input-group>
                            <b-form-input v-model="srcPassword" type="password" :placeholder="$t('sec_password')"
                                required></b-form-input>
                        </b-input-group>
                        <b-link @click="forgetPwd">
                            <p class="text-primary text-12 text-right mb-0 mt-2 font-weight-semibold">{{
                                $t('forget_secpassword') }}</p>
                        </b-link>
                    </b-form-group>
 
                    <b-button class="d-block mt-4 ml-auto px-4" variant="primary" type="submit"
                        :disabled="$root.isLoading">{{
                            $root.isLoading ? $t("loading...") : $t("confirm") }}</b-button>

                </b-form>

            </div>

        </b-modal>
        <ForgetModal></ForgetModal>

    </div>
</template>
<script>
import ForgetModal from './forget-sec-password.vue';
export default {
    data() {
        return {
            srcPassword: '',

        }
    },
    components: {
        ForgetModal
    },
    methods: {
        forgetPwd() {
            this.$bvModal.hide('modal-src-password');
            this.$bvModal.show('forget_secpassword');
        },
        confirmPassword() {
            this.$bus.$emit('passwordConfirmed', this.srcPassword);
            this.srcPassword = "";
            this.$bvModal.hide('modal-src-password');
        },
    }

}
</script>