<template>
    <div>


        <div class="header_wrap"
            v-if="!$route.path.includes('sessions') && !$route.path.includes('register') && !$route.path.includes('set-sec-password')"
            :class="{ 'pb-4': $route.path.includes('homepage') }">
            <div class="background-image"></div>
            <div class="content">
                <div class="d-flex justify-content-between align-items-center">
                    <div class="logo py-2">
                        <img :src="require('./assets/images/logo/logo.png')" alt="" width="auto" height="35px">

                    </div>
                    <div class="justify-content-start align-items-center flex-grow-1 mx-5 d-md-flex d-none">
                        <router-link tag="a" to="/web/homepage" class="">                        
                                <img v-if="$parent.path == 'homepage'" src="./assets/images/xlogo_footer/dashboard_active.png"
                                    class="nav-icon mr-1" :class="{ active: $parent.path == 'homepage' }" />
                            <span class="nav-text" :class="{ active: $parent.path == 'homepage' }">
                                {{ $t("dashboard") }}
                            </span>
                        </router-link>
                        <router-link tag="a" to="/web/wallet" class="">
                            <img v-if="$parent.path == 'wallet'" src="./assets/images/xlogo_footer/wallet_active.png"
                            class="nav-icon mr-1" :class="{ active: $parent.path == 'wallet' }" />
                            <span class="nav-text" :class="{ active: $parent.path == 'wallet' }">
                                {{ $t("wallet") }}
                            </span>
                        </router-link>
                        <router-link tag="a" to="/web/trade" class="">
                            <img v-if="$parent.path == 'trade'" src="./assets/images/xlogo_footer/trade_active.png"
                                class="nav-icon mr-1" :class="{ active: $parent.path == 'trade' }" />
                            <span class="nav-text" :class="{ active: $parent.path == 'trade' }">
                                {{ $t("trade") }}
                            </span>
                        </router-link>
                        <router-link tag="a" to="/web/ib_tree" class="">
                            <img v-if="$parent.path == 'ib_tree'" src="./assets/images/xlogo_footer/ib_tree_active.png"
                                class="nav-icon mr-1" :class="{ active: $parent.path == 'ib_tree' }" />
                            <span class="nav-text"
                                :class="{ active: $parent.path == 'ib_tree' }">
                                {{ $t("ib_tree") }}
                            </span>
                        </router-link>
                        <router-link tag="a" to="/web/commission" class="">
                            <img v-if="$parent.path == 'commission'" src="./assets/images/xlogo_footer/commission_active.png"
                                    class="nav-icon mr-1" :class="{ active: $parent.path == 'commission' }" />
                            <span class="nav-text"
                                :class="{ active: $parent.path == 'commission' }">
                                {{ $t("commission") }}
                            </span>
                        </router-link>

                    </div>
                    <div class="d-flex justify-content-between align-items-center">
                        <div class="text-14 text-white ml-4 d-md-block d-none">
                            <b-button variant="primary" class="text-14" @click="$router.push('/web/trade')">{{
                                $t('start_trading') }}</b-button>

                        </div>
                        <div class="ml-4" style="">
                            <b-dropdown id="dropdown-lang" right variant="link" toggle-class="my-dropdown">

                                <template #button-content>
                                    <!-- <img :src="require(`./assets/images/flag/${$i18n.locale}.png`)" alt="" width="20px"> -->
                                    <span class="text-white text-14 text-uppercase">{{ $i18n.locale }}</span>
                                </template>
                                <b-dropdown-item href="#" @click="$root.changeLan('en')">English</b-dropdown-item>
                                <b-dropdown-item href="#" @click="$root.changeLan('zh')">中文</b-dropdown-item>
                                <b-dropdown-item href="#" @click="$root.changeLan('in')">Indon</b-dropdown-item>
                            </b-dropdown>
                        </div>
                        <div id="tooltip-button-1" class="text-20  ml-4 text-white" @click="show = !show">
                            <i class="fa-regular fa-bell"></i>
                        </div>

                        <b-tooltip :show.sync="show" target="tooltip-button-1" placement="bottom">
                            <div class="title-news text-left" >
                                {{ $t('news') }}
                            </div>
                            <div class="title-content d-flex" @click="openNews(item)" v-for="item in $root.newsList" :key="item.id">
                                <img :src="item.public_path" alt="" height="48px" width="48px">
                                <div class="content ml-2 text-left">
                                    <div class="text-14 font-weight-semibold mb-1 text-ellipsis" style="max-width: 340px;">{{ item.title }}</div>
                                    <div class="text-secondary mb-1 max-line-2 text-14">{{ item.description }}</div>
                                    <div class="text-secondary text-12">{{ item.updated_at }}</div>

                                </div>

                            </div>
                        </b-tooltip>
                        <div class="text-20 text-white ml-4 d-md-block d-none">
                            <b-dropdown id="dropdown-lang" right variant="link" toggle-class="my-dropdown">

                                <template #button-content>
                                    <span class="text-white text-20 text-uppercase"><i
                                            class="fa-regular fa-circle-user"></i></span>
                                </template>
                                <b-dropdown-item href="#" @click="$router.push('/web/profile?type=1')">{{ $t("account_info")
                                }}</b-dropdown-item>
                                <b-dropdown-item href="#" @click="$parent.logout">{{ $t('logout') }}</b-dropdown-item>
                            </b-dropdown>

                        </div>
                        <div class="text-20 text-white ml-4 d-md-none d-block" v-b-toggle.sidebar-right>
                            <i class="fa-solid fa-bars"></i>
                        </div>

                    </div>
                </div>


                <div class="justify-content align-items-end mt-4 mb-2 d-md-flex d-none"
                    v-if="!$route.path.includes('sessions') && !$route.path.includes('register') && !$route.path.includes('homepage')">
                    <h3 class="mt-3 text-white text-20 font-weight-semibold">{{ $t($parent.title) }}</h3>
                </div>
                <div class="justify-content align-items-end mt-4 mb-2 d-md-flex d-none"
                    v-else-if="$route.path.includes('homepage')">
                    <h3 class="mt-3 text-white text-20 font-weight-semibold">{{ $t('welcome') }} {{ $root.username }}</h3>
                </div>
                <div class="justify-content align-items-end mt-4 mb-2 d-flex d-md-none"
                    v-if="$route.path.includes('homepage')">
                    <h3 class="mt-3 text-white text-20 font-weight-semibold">{{ $t('welcome') }} {{ $root.username }}</h3>
                </div>

            </div>
        </div>
        <div class="d-flex justify-content align-items-end d-sm-none"
            v-if="!$route.path.includes('sessions') && !$route.path.includes('set-sec-password') && !$route.path.includes('register') && !$route.path.includes('homepage')">
            <h3 class="my-3 mx-4 text-20 font-weight-semibold">{{ $t($parent.title) }}</h3>
        </div>
        <b-sidebar id="sidebar-right" title="Sidebar" no-header right shadow backdrop>
            <div class="profile-outer-box mb-4">
                <div class="background-image"></div>
                <div class="bg-overlay"></div>
                <div class="profile-box">
                    <span class="text-64" style="color: #EBF9FF;">
                        <i class="fa-regular fa-circle-user"></i>
                    </span>
                    <!-- <img :src="require('./assets/images/xlogo_signin/top_xlogo.png')" alt="" width="100%"> -->
                    <h4 class="text-18 mb-4 mt-2 text-center text-white">{{ $root.username }}</h4>
                    <b-button variant="primary" @click="$router.push('/web/trade')">{{ $t('start_trading') }}</b-button>

                </div>
            </div>

            <div class="text-left mb-4">
                <router-link tag="a" to="/web/profile?type=1" class="">
                    <span class="nav-text">
                        {{ $t("account_info") }}
                    </span>
                </router-link>
            </div>

            <div class="text-left mb-4">
                <router-link tag="a" to="/web/profile?type=2" class="">
                    <span class="nav-text">
                        {{ $t("sec_password") }}
                    </span>
                </router-link>
            </div>

            <div class="text-left mb-4">
                <router-link tag="a" to="/web/profile?type=3" class="">
                    <span class="nav-text">
                        {{ $t("kyc") }}
                    </span>
                </router-link>
            </div>

            <div class="text-left mb-4">
                <router-link tag="a" to="/web/profile?type=4" class="">
                    <span class="nav-text">
                        {{ $t("withdrawal") }}
                    </span>
                </router-link>
            </div>
            <div class="flex-grow-1"></div>

            <div class="text-left mb-4">
                <span class="nav-text logout" @click="$parent.logout">
                    {{ $t("logout") }}
                </span>
            </div>
        </b-sidebar>
    </div>
</template>
<script>
export default {
    data(){
        return {
            show: false,
        }
    },
    methods: {
        openNews(item){
            this.show = !this.show;
            this.$root.newsItem = item;
            this.$bvModal.show('modal-news');

        }
    }

}
</script>