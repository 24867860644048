<template>
    <div v-if="!$route.path.includes('sessions') && !$route.path.includes('set-sec-password') && !$route.path.includes('register')"
        class="footer_wrap mt-auto" ref="footer">
        <!-- Footer Start -->
        <div class="app-footer">
            <b-row align-h="around" align-v="end" no-gutters class="mb-2">
                <b-col cols="2" class="position-relative">
                    <router-link tag="a" to="/web/homepage" class="nav-item-hold">
                        <div class="nav-icon">
                            <img :src="$parent.path == 'homepage' ? require('./assets/images/xlogo_footer/dashboard_active.png') : require('./assets/images/xlogo_footer/dashboard.png')"
                                class="nav-icon" :class="{ active: $parent.path == 'homepage' }" />
                        </div>
                        <span class="nav-text" :class="{ active: $parent.path == 'homepage' }">
                            {{ $t("dashboard") }}
                        </span>
                    </router-link>
                </b-col>
                <b-col cols="2">
                    <router-link tag="a" to="/web/wallet" class="nav-item-hold ">
                        <img :src="$parent.path == 'wallet' ? require('./assets/images/xlogo_footer/wallet_active.png') : require('./assets/images/xlogo_footer/wallet.png')"
                            class="nav-icon" :class="{ active: $parent.path == 'wallet' }" />
                        <span class="nav-text" :class="{ active: $parent.path == 'wallet' }">{{
                            $t("wallet")
                        }}</span>
                    </router-link>
                </b-col>



                <b-col cols="2" class="position-relative h-100">
                    <router-link tag="a" to="/web/trade" class="nav-item-hold ">
                        <div class="img-box centering">
                            <img src="./assets/images/logo/logo.svg" alt="" width="100%">
                        </div>
                        <img :src="require('./assets/images/xlogo_footer/ib_tree_active.png')" class="nav-icon opacity-0" />
                        <span class="nav-text mt-auto" :class="{ active: $parent.path == 'trade' }">{{
                            $t("trade")
                        }}</span>
                    </router-link>
                </b-col>

                <b-col cols="2">
                    <router-link tag="a" to="/web/ib_tree" class="nav-item-hold">
                        <img :src="$parent.path == 'ib_tree' ? require('./assets/images/xlogo_footer/ib_tree_active.png') : require('./assets/images/xlogo_footer/ib_tree.png')"
                            class="nav-icon" :class="{ active: $parent.path == 'ib_tree' }" />
                        <span class="nav-text" :class="{ active: $parent.path == 'ib_tree' }">{{
                            $t("ib_tree")
                        }}</span>
                    </router-link>
                </b-col>

                <b-col cols="2">
                    <router-link tag="a" to="/web/commission" class="nav-item-hold">
                        <img :src="$parent.path == 'commission' ? require('./assets/images/xlogo_footer/commission_active.png') : require('./assets/images/xlogo_footer/commission.png')"
                            class="nav-icon" :class="{ active: $parent.path == 'commission' }" />
                        <span class="nav-text" :class="{ active: $parent.path == 'commission' }">{{
                            $t("commission")
                        }}</span>
                    </router-link>
                </b-col>
            </b-row>
            <!-- fotter end -->
        </div>
    </div>
</template>
<script>
export default{

}
</script>